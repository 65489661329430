$(function() {

    $('#login_form').validator({
        custom: {
            required: function($el) {
                return !!$.trim($el.val());
            },
            checkbox: function($el) {
                return !!$el.prop('checked');
            }
        },
        errors: {
            required: 'To pole nie może być puste',
            checkbox: 'Akceptacja regulaminu jest wymagana'
        }
    });

});
