$(function() {

    $('#confirm-delete').on('show.bs.modal', function(e) {
        $(this).find('.btn-ok').attr('href', $(e.relatedTarget).data('href'));
        $(this).find('.type').text($(e.relatedTarget).data('type'));
        $(this).find('.title').text($(e.relatedTarget).data('title'));
    });


    $('#step-1').validator({
        custom: {
            required: function($el) {
                return !!$.trim($el.val());
            },
            checkbox: function($el) {
                return !!$el.prop('checked');
            }
        },
        errors: {
            required: 'To pole nie może być puste',
            checkbox: 'Akceptacja regulaminu jest wymagana'
        }
    });
    $('#step-2, #form_settings').validator({
        custom: {
            required: function($el) {
                return !!$.trim($el.val());
            },
            nip: function($el) {
                if ($.trim($el.val())) {
                    return validateNip($el.val());
                } else {
                    return true;
                }
            }
        },
        errors: {
            required: 'To pole nie może być puste',
            nip: 'Niepoprawny format nipu'
        }
    });

    $('.captcha').on('click', function() {
        $("#fakeLoader").fakeLoader({
            timeToHide: 100000,
            spinner: "spinner7", //Options: 'spinner1', 'spinner2', 'spinner3', 'spinner4', 'spinner5', 'spinner6', 'spinner7'
            bgColor: "rgba(46, 44, 48, 0.6)",
        });
        var captcha = $('img.captcha-img-register');
        var config = captcha.data('refresh-config');
        $.ajax({
            method: 'GET',
            url: '/get_captcha_register/' + config,
        }).done(function(response) {
            captcha.prop('src', response);
            $('#fakeLoader').remove();
            $('body').prepend('<div id="fakeLoader"></div>');
        });
    });

    function validateNip(nip) {
        var nip_ = nip.replace(/-/g, "");
        var reg = /^[0-9]{10}$/;
        if (reg.test(nip_) === false) {
            return false;
        } else {
            var dig = ("" + nip_).split("");
            var control = (6 * parseInt(dig[0]) + 5 * parseInt(dig[1]) + 7 * parseInt(dig[2]) + 2 * parseInt(dig[3]) + 3 * parseInt(dig[4]) + 4 * parseInt(dig[5]) + 5 * parseInt(dig[6]) + 6 * parseInt(dig[7]) + 7 * parseInt(dig[8])) % 11;
            if (parseInt(dig[9]) == control)
                return true;
            else
                return false;
        }

    }
    $('#copyFromAddress').on('click', function() {
        var inputStreet = $('#Street').val();
        var inputPostCode = $('#PostCode').val();
        var inputPlace = $('#Place').val();
        var province = $('#province').val();
        var inputCountry = $('#Country').val();

        $('#Street_i').val(inputStreet);
        $('#PostCode_i').val(inputPostCode);
        $('#Place_i').val(inputPlace);
        $('#province_i').val(province);
        $('#province_i').selectpicker('refresh');
        $('#Country_i').val(inputCountry);
    });
});
